import React from "react"

const IndiceElement = ({ bookslug, planslug, chapter, slug, parent }) => {
  return (
    <>
      {chapter ? (
        <li key={chapter.id} className="flex items-center mb-0.5">
          <div className="flex justify-between items-center w-full">
            <div className="flex-1">
              <div className="flex items-center space-x-5">
                <div
                  className="flex-1"
                  style={chapter.depth && { marginLeft: "-9px" }}
                >
                  <a
                    className="block py-2 hover:underline"
                    style={{ marginLeft: `${chapter.depth * 15 - 35}px` }}
                    indent={chapter.depth}
                    href={
                      bookslug
                        ? `http://atril.clicksound.io:3000/r/` +
                          bookslug +
                          "/" +
                          chapter.slug
                        : planslug
                        ? `http://atril.clicksound.io:3000/r/plan-` +
                          planslug +
                          "/" +
                          chapter.slug
                        : "http://atril.clicksound.io:3000/login"
                    }
                  >
                    {chapter.title.replaceAll("*", "")}
                  </a>
                </div>
              </div>
            </div>

            {/* <div className="m-0">
            <svg xmlns="http://www.w3.org/2000/svg" className={(chapter.isCompleted ? "text-gray-600" : "text-gray-600 opacity-30" ) + " h-4 w-4"} viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
          </div> 

          <div size={18} strokeWidth={"20"} style={{marginLeft: '5px'}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="opacity-40"
              color={chapter.isSaved ? "tomato" : "#4f4f4f"}
              width={12}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
            </svg>
          </div> */}
          </div>
        </li>
      ) : null}
    </>
  )
}

export default IndiceElement
