import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import _ from "lodash"
import { ArrowLeft, ArrowRight } from "react-feather"
import { createGlobalStyle } from "styled-components"
import { Container } from "../components/lib/Container"
import IndiceElement from "./../components/IndiceElement"
import CallToAction from "../components/sections/CallToActionSection"
import { Button } from "@atrilflamenco/react-components"
import Footer from "../components/lib/Footer"

const BookStyles = createGlobalStyle`
  body {
    position: relative;
    // background-color: ${props =>
      /Ritmo/.test(props.book)
        ? `#ffa54a`
        : /palmas/.test(props.book)
        ? `#DDF7DB`
        : /musical/.test(props.book)
        ? `#ff9bc4`
        : /Mairena/.test(props.book)
        ? `lightgray`
        : `transparent`};
    // background-color: #FFC17A;
    // background-image: linear-gradient(140deg, #ffffff7a, #ffffff00 80%);
    // background-size: 100% 148%;
    // background-position: left;
    // background-repeat: no-repeat;
    // background-attachment: fixed;
  }
  h1 {
    font-family: 'Source Sans Pro', sans-serif;
  }
  header {
    background-color: transparent !important;
  }
`

export default function Plan({ data }) {
  const { recurso: book } = data.atril
  let parent = null
  const [moreText, setMoreText] = useState(true)
  const maxStringLength = 900

  return (
    <>
      <BookStyles bg={book.image} book={book.titulo} />
      <Container className="text-gray-800">
        <div className="max-w-screen-xl mx-auto mb-20 grid md:grid-cols-1 lg:grid-cols-2 gap-10">
          <img
            className="hidden md:block xs:static lg:sticky top-28 w-full shadow-xl"
            src={`https://api.atrilflamenco.com:5000/${book.image}`}
            alt={`Portada ${book.title}`}
          />
          <div className="pt-0 md:pt-24">
            <Link
              className="flex items-center space-x-3 mb-5"
              to="/lecturas"
              replace
            >
              <ArrowLeft color="currentColor" strokeWidth={2} size={16} />
              <h2 className="font-semibold text-gray-900">Lecturas</h2>
            </Link>
            <h1 className="text-6xl font-bold leading-none mb-8">
              {book.titulo}
            </h1>
            {book.subtitulo && (
              <h3
                className="text-2xl font-bold max-w-3xl mb-16"
                dangerouslySetInnerHTML={{ __html: book.subtitulo }}
              />
            )}
            <Link to={`/${_.kebabCase(book.author)}`}>
              <h6 className="text-lg font-bold mb-8">{book.author}</h6>
            </Link>
            <div className="relative">
              <div
                className="leading-relaxed space-y-3 text-justify mx-auto max-w-5xl"
                dangerouslySetInnerHTML={{
                  __html:
                    moreText && book.descripcion.length >= maxStringLength
                      ? _.truncate(book.descripcion, {
                          length: maxStringLength,
                          separator: /,? +/,
                        })
                      : book.descripcion,
                }}
              />
              {book.descripcion.length >= maxStringLength ? (
                <button
                  className="text-sm rounded p-1 w-full mt-3 max-w-5xl"
                  onClick={() => setMoreText(!moreText)}
                >
                  {moreText ? `Ver más ↓` : `Ver menos ↑`}
                </button>
              ) : null}
            </div>

            <div className="flex items-center justify-center flex-col space-y-5 mt-20 mb-32">
              <Button
                size="lg"
                icon={
                  <ArrowRight color="currentColor" strokeWidth={3} size={18} />
                }
                iconPosition="end"
                variant="contained"
                href={
                  book.tableOfContents[0]?.slug
                    ? `http://atril.clicksound.io:3000/r/` +
                      book.slug +
                      "/" +
                      book.tableOfContents[0]?.slug
                    : "http://atril.clicksound.io:3000/login"
                }
              >
                Empezar libro
              </Button>
            </div>
            <div className="max-w-4xl mx-auto">
              <h3 className="text-2xl mb-8 font-bold">Índice de contenidos</h3>
              <ul>
                {book.tableOfContents
                  .filter(
                    indice =>
                      (indice.depth !== 1 && indice.depth <= 3) ||
                      (indice.parentId === null && !/notas$/.test(indice.slug))
                  )
                  .sort((a, b) => a.position - b.position)
                  .map((indice, i) => {
                    if (indice.depth < 4 || indice.parentId === null)
                      parent = indice.slug
                    return (
                      <div key={indice.id}>
                        <IndiceElement
                          key={indice.id}
                          bookslug={book.slug}
                          chapter={indice}
                          parent={parent}
                        />

                        {book.tableOfContents
                          .filter(
                            indiceSmall =>
                              indiceSmall.parentId + "" === indice.id + ""
                          )
                          .sort((a, b) => a.position - b.position)
                          .map((indiceSmall, iSmall) => {
                            if (
                              indiceSmall.depth < 4 ||
                              indice.parentId === null
                            )
                              parent = indiceSmall.slug
                            return (
                              <IndiceElement
                                key={indiceSmall.id}
                                bookslug={book.slug}
                                chapter={indiceSmall}
                                parent={parent}
                              />
                            )
                          })}
                      </div>
                    )
                  })}
              </ul>
            </div>
          </div>
        </div>
      </Container>
      <CallToAction />
      <Footer />
    </>
  )
}

export const query = graphql`
  query Book($id: ID!) {
    atril {
      recurso(id: $id) {
        titulo
        subtitulo
        image
        descripcion
        author
        color
        slug
        tableOfContents {
          id
          title
          depth
          slug
          parentId
        }
      }
    }
  }
`
